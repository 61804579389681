import React from 'react';
import { Link } from 'react-router-dom';
import '../Conditions.css';

const CookieSettings = () => {
  return (
    <div className="conditions-page">
      <nav className="conditions-navbar">
        <div className="conditions-navbar-content">
          <Link to="/" className="conditions-logo">
            <img src="/img/logo.svg" alt="Scribbre Logo" className="conditions-logo-icon" />
          </Link>
        </div>
      </nav>

      <main className="conditions-content-wrapper">
        <h1 className="conditions-page-title">Cookie Settings</h1>
        <p className="conditions-last-updated">Last Updated: October 28, 2024</p>

        <div className="conditions-section">
          <h2 className="conditions-section-title">1. What Are Cookies?</h2>
          <div className="conditions-section-content">
            <p>Cookies are small text files that are stored on your device when you visit a website. They help websites function properly and gather data on how users interact with them.</p>
          </div>
        </div>

        <div className="conditions-section">
          <h2 className="conditions-section-title">2. Types of Cookies We Use</h2>
          <div className="conditions-section-content">
            <ul>
              <li><strong>Essential Cookies:</strong> These cookies are necessary for the website to function and cannot be switched off</li>
              <li><strong>Performance Cookies:</strong> These cookies collect anonymous data on how visitors use the site</li>
              <li><strong>Functionality Cookies:</strong> These cookies enable enhanced functionality and personalization</li>
              <li><strong>Targeting Cookies:</strong> We use these cookies to understand your interests and show relevant content</li>
            </ul>
          </div>
        </div>

        <div className="conditions-section">
          <h2 className="conditions-section-title">3. Managing Your Cookie Preferences</h2>
          <div className="conditions-section-content">
            <p>You can manage your cookie preferences at any time by adjusting your browser settings. However, please note that disabling certain cookies may affect the functionality of our website.</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CookieSettings;
