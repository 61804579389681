import React from 'react';
import { Link } from 'react-router-dom';
import '../Conditions.css';

const TermsOfService = () => {
  return (
    <div className="conditions-page">
      <nav className="conditions-navbar">
        <div className="conditions-navbar-content">
          <Link to="/" className="conditions-logo">
            <img src="/img/logo.svg" alt="Scribbre Logo" className="conditions-logo-icon" />
          </Link>
        </div>
      </nav>

      <main className="conditions-content-wrapper">
        <h1 className="conditions-page-title">Terms of Service</h1>
        <p className="conditions-last-updated">Last Updated: October 28, 2024</p>

        <div className="conditions-section">
          <h2 className="conditions-section-title">1. Acceptance of Terms</h2>
          <div className="conditions-section-content">
            <p>By using our website (including booking a demo, submitting inquiries, or browsing content), you agree to these Terms of Service and our Privacy Policy. If you do not agree, please do not use our services.</p>
          </div>
        </div>

        <div className="conditions-section">
          <h2 className="conditions-section-title">2. Services Provided</h2>
          <div className="conditions-section-content">
            <p>Scribbre.com offers digital solutions for product manual management, AI-driven support, product registration, and data insights, as described on our website. We reserve the right to modify or discontinue any features of our platform without notice.</p>
          </div>
        </div>

        <div className="conditions-section">
          <h2 className="conditions-section-title">3. User Responsibilities</h2>
          <div className="conditions-section-content">
            <ul>
              <li>You agree to provide accurate and complete information when filling out forms on our website</li>
              <li>You must not misuse our website or engage in illegal or unauthorized activities</li>
            </ul>
          </div>
        </div>

        <div className="conditions-section">
          <h2 className="conditions-section-title">4. Intellectual Property</h2>
          <div className="conditions-section-content">
            <p>All content, design, logos, and other intellectual property on this website are owned by Scribbre.com and are protected by copyright and trademark laws.</p>
          </div>
        </div>

        <div className="conditions-section">
          <h2 className="conditions-section-title">5. Governing Law</h2>
          <div className="conditions-section-content">
            <p>These Terms of Service shall be governed by and construed in accordance with the laws of Germany, without regard to its conflict of law provisions.</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TermsOfService;
