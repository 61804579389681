import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Check, Loader } from 'lucide-react';
import '../Contact.css';

const ContactPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    email: location.state?.email || '',
    additionalInfo: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const expectations = [
    'Personalized walkthrough of our platform',
    'Q&A session with our product specialist',
    'Custom pricing discussion based on your needs',
    'Free consultation on digital transformation'
  ];

  // Validation function
  const validateForm = () => {
    const newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full name is required';
    }

    if (!formData.companyName.trim()) {
      newErrors.companyName = 'Company name is required';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const submitForm = async (data) => {
    // Replace with your actual API endpoint
    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      return await response.json();
    } catch (error) {
      throw new Error('Failed to submit form. Please try again later.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError('');

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const form = e.target;
      const data = new FormData(form);

      const response = await fetch('/', {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(data).toString()
      });

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      // Add this console.log to verify the submission
      console.log('Form submitted successfully');

      navigate('/thank-you');
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitError('Failed to submit form. Please try again later.');
      setIsSubmitting(false);
    }
};

  return (
    <div className="contact-page">
      {/* Navigation */}
      <nav className="navbar">
        <div className="navbar-content">
          <div className="logo">
            <Link to="/">
              <img src="/img/logo.svg" alt="Logo Visual" className="logo-icon" />
              <img src="/img/logo-text.svg" alt="Logo Text" className="logo-text" />
            </Link>
          </div>
          <ul className="nav-links">
            <li><Link to="/">Home</Link></li>
          </ul>
        </div>
      </nav>

      {/* Main Content */}
      <main className="contact-main">
        <div className="contact-container">
          <div className="contact-grid">
            {/* Left Column */}
            <div className="contact-left">
              <div className="header-content">
                <h1>Book Your Demo</h1>
                <p>
                  Let's explore how our digital manual solution can transform your business.
                  Fill out the form and our team will get back to you within 24 hours.
                </p>
              </div>

              <div className="expectations-card">
                <h3>What to expect:</h3>
                <ul className="expectations-list">
                  {expectations.map((item, index) => (
                    <li key={index}>
                      <Check className="check-icon" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Right Column - Form */}
            <div className="contact-right">
              <form
                onSubmit={handleSubmit}
                className="contact-form"
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
              >
                {/* Hidden input for Netlify */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <input name="bot-field" />
                </div>

                <div className="form-group">
                  <label>Full Name*</label>
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    className={errors.fullName ? 'error' : ''}
                  />
                  {errors.fullName && <span className="error-message">{errors.fullName}</span>}
                </div>

                <div className="form-group">
                  <label>Company Name*</label>
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    className={errors.companyName ? 'error' : ''}
                  />
                  {errors.companyName && <span className="error-message">{errors.companyName}</span>}
                </div>

                <div className="form-group">
                  <label>Business Email*</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className={errors.email ? 'error' : ''}
                  />
                  {errors.email && <span className="error-message">{errors.email}</span>}
                </div>

                <div className="form-group">
                  <label>Additional Information</label>
                  <textarea
                    name="additionalInfo"
                    value={formData.additionalInfo}
                    onChange={handleInputChange}
                    rows={4}
                    placeholder="Tell us about your needs and expectations..."
                  />
                </div>

                {submitError && (
                  <div className="error-message submit-error">
                    {submitError}
                  </div>
                )}

                <button
                  type="submit"
                  className="submit-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span className="button-content">
                      <Loader className="spinner" />
                      Submitting...
                    </span>
                  ) : (
                    'Schedule Demo'
                  )}
                </button>

                <p className="form-disclaimer">
                  By submitting this form, you agree to our{' '}
                  <Link to="/terms-of-service">Terms of Service</Link> and{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactPage;
