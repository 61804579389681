import React from 'react';
import { Link } from 'react-router-dom';
import { Check, ChevronRight } from 'lucide-react';
import '../ThankYou.css';

const ThankYouPage = () => {
  const nextSteps = [
    "You'll receive a confirmation email within 5 minutes",
    "Our team will contact you within 24 hours",
    "We'll prepare a personalized demo based on your needs",
    "Together, we'll start your digital transformation journey"
  ];

  const CheckmarkSVG = () => (
    <svg viewBox="0 0 100 100" width="100" height="100">
      <circle cx="50" cy="50" r="45" fill="#4DAF4F"></circle>
      <path d="M30 50 L45 65 L70 35" stroke="white" strokeWidth="8" fill="none" />
    </svg>
  );

  return (
    <div className="thank-you-page">
      {/* Navigation */}
      <nav className="navbar">
        <div className="navbar-content">
          <div className="logo">
            <Link to="/">
              <img src="/img/logo.svg" alt="Logo Visual" className="logo-icon" />
              <img src="/img/logo-text.svg" alt="Logo Text" className="logo-text" />
            </Link>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <section id="thank-you">
        <div className="thank-you-container">
          <div className="thank-you-content">
            <div className="thank-you-image">
              <CheckmarkSVG />
            </div>

            <h1>Thank You for Taking the First Step!</h1>

            <div className="message">
              <p className="primary">The trees are already whispering their gratitude... 🌳</p>
              <p className="secondary">Your inbox will soon be greener with our demo invitation.</p>
            </div>

            {/* Moved CTA buttons here */}
            <div className="cta-buttons">
              <Link to="/" className="button secondary">
                Return to Home
              </Link>
              <a
                href="https://www.linkedin.com/company/scribbre"
                target="_blank"
                rel="noopener noreferrer"
                className="primary-button"
              >
                <span className="button-content">
                  Follow Us on LinkedIn
                  <ChevronRight size={20} />
                </span>
              </a>
            </div>

            <div className="next-steps">
              <h3>What's Next?</h3>
              <ul>
                {nextSteps.map((step, index) => (
                  <li key={index}>
                    <Check className="check-icon" />
                    {step}
                  </li>
                ))}
              </ul>
            </div>

            <div className="fun-fact">
              <p>
                Did you know? The average company saves 27 trees per year by switching to digital manuals.
                Imagine how many trees you'll save in your digital journey!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-main">
            <div className="footer-logo">
              <Link to="/">
                <img src="/img/logo.svg" alt="Logo" className="logo-icon" />
                <img src="/img/logo-text.svg" alt="Logo Text" className="logo-text" />
              </Link>
            </div>
            <div className="footer-social">
              <a
                href="https://www.linkedin.com/company/scribbre"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/LinkedIn.svg" alt="LinkedIn" />
              </a>
            </div>
          </div>
          <div className="footer-separator" />
          <div className="footer-bottom">
            <span className="copyright">© 2024 scribbre. All rights reserved.</span>
            <div className="footer-links">
              <a href="#">Privacy Policy</a>
              <a href="#">Terms of Service</a>
              <a href="#">Cookies Settings</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ThankYouPage;
