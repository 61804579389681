import React from 'react';
import { Link } from 'react-router-dom';
import '../Conditions.css';

const PrivacyPolicy = () => {
  return (
    <div className="conditions-page">
      <nav className="conditions-navbar">
        <div className="conditions-navbar-content">
          <Link to="/" className="conditions-logo">
            <img src="/img/logo.svg" alt="Scribbre Logo" className="conditions-logo-icon" />
          </Link>
        </div>
      </nav>

      <main className="conditions-content-wrapper">
        <h1 className="conditions-page-title">Privacy Policy</h1>
        <p className="conditions-last-updated">Last Updated: October 28, 2024</p>

        <div className="conditions-section">
          <h2 className="conditions-section-title">1. Data We Collect</h2>
          <div className="conditions-section-content">
            <p>We only collect personal data that you provide directly through our contact forms and interactions on our website, including:</p>
            <ul>
              <li><strong>Contact Information:</strong> Full name, company name, business email, phone number</li>
              <li><strong>Optional Information:</strong> Additional details like the number of product manuals you manage</li>
              <li><strong>Device Information:</strong> IP addresses, browser types, and basic device details</li>
              <li><strong>Usage Data:</strong> Information regarding how you interact with our website</li>
            </ul>
          </div>
        </div>

        <div className="conditions-section">
          <h2 className="conditions-section-title">2. How We Use Your Data</h2>
          <div className="conditions-section-content">
            <p>The data we collect is used to:</p>
            <ul>
              <li>Respond to your inquiries or demo requests</li>
              <li>Provide personalized demos and discussions based on your needs</li>
              <li>Improve our website's functionality and user experience</li>
              <li>Ensure site security and compliance with regulations</li>
            </ul>
          </div>
        </div>

        <div className="conditions-section">
          <h2 className="conditions-section-title">3. Your Data Rights</h2>
          <div className="conditions-section-content">
            <p>You have the right to:</p>
            <ul>
              <li>Access, correct, or delete any personal data we hold about you</li>
              <li>Request a copy of your data or ask for it to be transferred</li>
              <li>Withdraw consent for the processing of your data at any time</li>
            </ul>
          </div>
        </div>

        <div className="conditions-section">
          <h2 className="conditions-section-title">4. Data Retention</h2>
          <div className="conditions-section-content">
            <p>We retain your personal data only for as long as is necessary for the purposes outlined above or as required by law.</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
