import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Menu, X, ChevronRight } from 'lucide-react';
import '../LandingPage.css';

export default function LandingPage() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [ctaEmail, setCtaEmail] = useState('');

  // Features data
  const features = [
    {
      icon: '/img/features-icon-digital-user-manual-management.svg',
      title: 'Digital User Manual Management and QR Code Generation',
      description:
        'Upload and manage product manuals easily through our cloud platform. Generate QR codes for quick access and update in real time to provide customers with the latest version.',
    },
    {
      icon: '/img/features-icon-ai-agent-assistance.svg',
      title: 'AI-Driven Intelligent Assistance for Instant Problem Resolution',
      description:
        'Offer real-time AI support in your manuals. Customers can ask questions and get instant, accurate answers, easing the load on your customer service teams and boosting user satisfaction.',
    },
    {
      icon: '/img/features-icon-user-data-statistics.svg',
      title: 'Advanced User Data Analytics and Insights for Product Optimization',
      description:
        'Monitor customer interactions with your manuals to gather valuable data like location, language, and engagement patterns. Use insights to enhance your documentation and product offerings.',
    },
    {
      icon: '/img/features-icon-ai-translation-accessibility.svg',
      title: 'Global Accessibility with AI-Powered Translation and Inclusive Features',
      description:
        'Provide multilingual, accessible manuals that meet WCAG standards, allowing users to choose their preferred language for inclusive global support.',
    },
    {
      icon: '/img/features-icon-seamless-product-registration.svg',
      title: 'Effortless Product Registration System to Boost User Engagement',
      description:
        'Streamline onboarding with our product registration feature. Collect key customer data and connect it to the product. Boost customer engagement from day one and gather data for targeted support and marketing.',
    },
  ];

  // Benefits data
  const benefits = [
    {
      icon: '/img/benefits-icon-reduce-costs-improve-efficiency.svg',
      title: 'Reduce Costs and Improve Efficiency',
      description:
        'Eliminate printing, shipping, and support costs by switching to digital manuals, cutting expenses across your operations.',
    },
    {
      icon: '/img/benefits-icon-gain-key-insights.svg',
      title: 'Gain Key Insights into Your Market',
      description:
        'Use demographics and behavior data to segment audiences, tailor strategies, and make decisions aligned with customer needs.',
    },
    {
      icon: '/img/benefits-icon-ensure-regulatory-compliance.svg',
      title: 'Ensure Accessibility Compliance',
      description:
        'Meet EU and global accessibility standards with our solution, ensuring compliance and maintaining a competitive edge.',
    },
    {
      icon: '/img/benefits-icon-engage-customers-seamlessly.svg',
      title: 'Engage Customers Seamlessly',
      description:
        'Create interactive, personalized experiences that drive engagement and improve customer satisfaction.',
    },
    {
      icon: '/img/benefits-icon-support-sustainability-goals.svg',
      title: 'Support Sustainability Goals',
      description:
        'Switch to digital manuals to conserve natural resources and significantly reduce your carbon footprint, aligning your business with sustainability goals.',
    },
  ];

  // Pricing plans data
  const pricingPlans = [
    {
      id: 'essentials',
      name: 'Essentials',
      description:
        'Excellent for establishing the fundamentals necessary to deliver an outstanding user experience.',
      price: '€99',
      period: '/monthly',
      features: [
        'Secure cloud hosting for manuals',
        'Product registration',
        'Basic user data',
        'Accessibility compliance',
        'Up to 3 product manuals',
        'Real-time updates',
        'Standard QR code generation',
      ],
    },
    {
      id: 'pro',
      name: 'Pro',
      description:
        'Ideal for businesses that provide between 3 to 10 products and engage with few markets.',
      price: '€299',
      period: '/monthly',
      features: [
        'All features from "Essentials"',
        'Advanced user data',
        'Advanced user analytics',
        'Translation with AI (Up to 7 Languages)',
        'Up to 10 product manuals',
        'Standard customer support',
        'Standard QR code generation',
      ],
    },
    {
      id: 'advanced',
      name: 'Advanced',
      description:
        'Ideal for established businesses that provide between 10 to 20 products and engage with multiple markets.',
      price: '€799',
      period: '/monthly',
      features: [
        'All features from "Pro"',
        'Translation with AI (Up to 20 Languages)',
        'Up to 20 product manuals',
        'Custom QR code generation',
        'Priority customer support',
      ],
    },
    {
      id: 'enterprise',
      name: 'Enterprise',
      description:
        'Ideal for enterprises with more than 20 products, a global presence. Pricing is assessed on a case-by-case basis.',
      price: 'Contact Us',
      period: '',
      features: [
        'All features from "Advanced"',
        'Unlimited AI Translation',
        'Unlimited Manuals',
        'Highest tier customer support and Dedicated Success Manager',
        'API access',
      ],
    },
  ];

  // Navigation handlers
  const handleHeroClick = () => {
    navigate('/contact');
  };

  const handlePricingSelection = (planId) => {
    navigate('/contact', {
      state: {
        source: 'pricing',
        selectedPlan: planId
      }
    });
  };

  const handleCtaSubmit = (e) => {
    e.preventDefault();
    navigate('/contact', {
      state: {
        source: 'cta',
        email: ctaEmail
      }
    });
  };

  const handleEmailChange = (e) => {
    setCtaEmail(e.target.value);
  };

  // Mobile menu handlers
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (e) => {
    e.preventDefault();
    const href = e.target.getAttribute('href');
    setIsMenuOpen(false);

    // Smooth scroll to section
    const element = document.querySelector(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Effect for mobile menu
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  return (
    <div className="landing-container">
      {/* Navbar Section */}
      <nav className="navbar">
        <div className="navbar-content">
          <div className="logo">
            <Link to="/">
              <img src="/img/logo.svg" alt="Scribbre Logo" className="logo-icon" />
              <img src="/img/logo-text.svg" alt="Scribbre" className="logo-text" />
            </Link>
          </div>

          <div className="nav-links desktop-menu">
            <a href="#features">Features</a>
            <a href="#benefits">Benefits</a>
            <a href="#pricing">Pricing</a>
          </div>

          <button
            className="mobile-menu-button"
            onClick={toggleMenu}
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
            <div className="mobile-menu-content">
              <a href="#features" onClick={handleMenuItemClick}>Features</a>
              <a href="#benefits" onClick={handleMenuItemClick}>Benefits</a>
              <a href="#pricing" onClick={handleMenuItemClick}>Pricing</a>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="hero-title">
              Revolutionize Your Product Manuals with Our Digital Platform
            </h1>
            <p className="hero-description">
              Cut costs, gain valuable customer insights, enhance onboarding experience, and reduce your carbon footprint by going digital with our innovative solution.
            </p>
            <div className="hero-cta">
              <button className="primary-button" onClick={handleHeroClick}>
                <span className="button-content">
                  Book a Demo
                  <ChevronRight size={20} />
                </span>
              </button>
            </div>
          </div>

          <div className="hero-image">
            <div className="image-container">
              <div className="image-background-effect"></div>
              <img
                src="/img/hero-illustration.svg"
                alt="Dashboard Preview"
                className="hero-illustration"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="features-section">
        <div className="features-container">
          <div className="features-header">
            <h2 className="features-title">
              Transform Your Onboarding Experience with Advanced Features
            </h2>
            <p className="features-section-description">
              Enhance and transform your onboarding experience with innovative and advanced features for maximum engagement and success.
            </p>
          </div>

          <div className="features-grid-wrapper">
            <div className="features-grid features-grid-top">
              {features.slice(0, 3).map((feature, i) => (
                <div key={i} className="feature-card">
                  <div className="feature-card-background" />
                  <div className="feature-card-content">
                    <div className="feature-icon">
                      <img src={feature.icon} alt={feature.title} />
                    </div>
                    <h3 className="feature-title">{feature.title}</h3>
                    <p className="feature-description">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="features-grid features-grid-bottom">
              {features.slice(3).map((feature, i) => (
                <div key={i + 3} className="feature-card">
                  <div className="feature-card-background" />
                  <div className="feature-card-content">
                    <div className="feature-icon">
                      <img src={feature.icon} alt={feature.title} />
                    </div>
                    <h3 className="feature-title">{feature.title}</h3>
                    <p className="feature-description">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section id="benefits" className="benefits-section">
        <div className="benefits-container">
          <div className="benefits-content">
            <div className="benefits-image-wrapper">
              <div className="benefits-illustration-container">
                <div className="image-background-effect"></div>
                <img
                  src="/img/benefit-illustration.svg"
                  alt="Benefits Illustration"
                  className="benefits-illustration"
                />
              </div>
            </div>

            <div className="benefits-text-content">
              <h2 className="benefits-title">
                Key Advantages of Switching to Digital Manuals
              </h2>
              <p className="benefits-description">
                Enhance efficiency, compliance, customer engagement, and sustainability with a seamless digital solution.
              </p>
              <div className="benefits-grid">
                {benefits.map((benefit, index) => (
                  <div key={index} className="benefit-card">
                    <div className="benefit-icon-wrapper">
                      <img
                        src={benefit.icon}
                        alt={benefit.title}
                        className="benefit-icon"
                      />
                    </div>
                    <div className="benefit-text">
                      <h3 className="benefit-card-title">{benefit.title}</h3>
                      <p className="benefit-card-description">{benefit.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="pricing-section">
        <div className="pricing-container">
          <div className="pricing-header">
            <h2 className="pricing-title">Simple, Transparent Pricing</h2>
            <p className="pricing-description">
              Affordable solutions tailored to your needs
            </p>
          </div>

          <div className="pricing-cards">
            {pricingPlans.map((plan) => (
              <div key={plan.id} className="pricing-card">
                <h3>{plan.name}</h3>
                <p>{plan.description}</p>

                <div className="price-container">
                  <span className="price">{plan.price}</span>
                  <span className="period">{plan.period}</span>
                </div>

                <h5>What's Included</h5>
                <ul>
                  {plan.features.map((feature, i) => (
                    <li key={i}>
                      <img src="/img/check-circle.svg" alt="Included feature" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <button
                  className="primary-button"
                  onClick={() => handlePricingSelection(plan.id)}
                  data-plan-id={plan.id}
                >
                  <span>Book a Demo</span>
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <div className="cta-container">
          <div className="cta-left">
            <h2>
              Discover How Our Solution Can Transform Your Business – Schedule a Demo Today!
            </h2>
          </div>

          <div className="cta-right">
            <p className="cta-description">
              We'd love to help you explore our offerings further! Please share your email address with us,
              and we can schedule a demo session to provide you with more details. Looking forward to connecting!
            </p>

            <form className="cta-form" onSubmit={handleCtaSubmit}>
              <input
                type="email"
                placeholder="Enter your email"
                value={ctaEmail}
                onChange={handleEmailChange}
                aria-label="Email address"
              />
              <button type="submit">
                <span>Get Started</span>
              </button>
            </form>

            <p className="cta-disclaimer">
              By signing up, you agree to our Terms and Conditions and Privacy Policy.
            </p>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-main">
            <div className="footer-logo">
              <img src="/img/logo.svg" alt="Logo" className="logo-icon" />
              <img src="/img/logo-text.svg" alt="Logo Text" className="logo-text" />
            </div>

            <div className="footer-social">
              <a
                href="https://www.linkedin.com/company/scribbre"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/LinkedIn.svg" alt="LinkedIn" />
              </a>
            </div>
          </div>

          {/* Separator Line */}
          <div className="footer-separator" />

          {/* Footer Bottom */}
          <div className="footer-bottom">
            <span className="copyright">© 2024 Scribbre. All rights reserved.</span>
            <div className="footer-links">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-of-service">Terms of Service</Link>
            <Link to="/cookie-settings">Cookies Settings</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
